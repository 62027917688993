import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    // EDITAR O AGREGAR ENSAYO
    leftbar_toggle: false,
    bool_form_steps: false,
    // LAYOUTS / DATA
    layout_form: {
      step: 1,
      data_ensayo_valid: false,
      data_ensayo: {
        cod_ensa: '',
        ens_tipo: null,
        ensa_des: '',
        ensa_ncorto: '',
        valid: false,
      },
      data_ensayo_original: null,
      data_matrices: [],
      patogenos_selected: [],
      pat_select: null,
      data_tecnicas: [],
    },
    obj_matrices: [
      { name: 'Superficies', id: 9 },
      { name: 'Ambiente', id: 6 },
      { name: 'Agua', id: 7 },
      { name: 'Manipulador', id: 8 },
      { name: 'Alimento', id: 12 },
    ],
    selects: {
      tipo_result: [],
      tipo_tecnicas: [],
      tipo_producto: [],
    },
    model: {},
    data_ensayo: {
      rsa: [],
      ensayosList: [],
      productosList: [],
      laboratoriosList: [],
      parametros: [],
      mix: [],
      tipo_resultados: [],
      tipo_ensayos: [],
      tecnicas: [],
      productosEmpresaslist: [],
      empresasList: [],
      cliente: null,
      nivelPeligro: [],
    },
    matrizunidad: {
      ali: 0,
      sup: 0,
      man: 0,
      agu: 0,
      amb: 0,
    },
    // OBJETO PARA TODO EL FORMILARIO
    form_data: {
      isComercial: false,
      step: 1,
      data_ensayo_valid: false,
      steriplex: false,
      kitsteriplex: 0,
      clasificacion: 1,
      parametrosSelected: [],
      parametrosedit: [],
      data_ensayo: {
        nombre: '',
        cod_ensa: '',
        ens_tipo: null,
        ensa_des: '',
        ensa_ncorto: '',
        valid: false,
        ensayoSelect: 0,
        empresa: 0,
        tipoEnsa: null,
        tipoMeto: null,
        descripcion: null,
        metodologia: null,
        numplacas: null,
        parametrosid: [],
        tiempoenriquecimiento: null,
        tiemporesult: null,
        tecnica: null,
        productoid: null,
        tipoRes: null,
        sitioAnalisis: null,
        mixes: null,
        nivelpeligrosidad: [],
      },
      isNew: true,
      data_ensayo_original: null,
      data_matrices: [],
      patogenos_selected: [],
      pat_select: null,
      data_tecnicas: [],
      matrices_unidades: [
        {
          id: 12,
          value: 'Alimento',
          unidades: '',
          activo: false,
        },
        {
          id: 9,
          value: 'Superficies',
          unidades: '',
          activo: false,
        },
        {
          id: 8,
          value: 'Manipuladores',
          unidades: '',
          activo: false,
        },
        {
          id: 7,
          value: 'Aguas',
          unidades: '',
          activo: false,
        },
        {
          id: 6,
          value: 'Ambientes',
          unidades: '',
          activo: false,
        },
      ],
      data_umbrales: {
        alimentosSelect: [
          {
            id: 1,
            rsa_id: [],
            umbral: '',
          },
        ],
        superficiesSelect: [
          {
            id: 1,
            rsa_id: [],
            umbral: '',
          },
        ],
        manipuldoresSelect: [
          {
            id: 1,
            rsa_id: [],
            umbral: '',
          },
        ],
        aguasSelect: [
          {
            id: 1,
            rsa_id: [],
            umbral: '',
          },
        ],
        ambientesSelect: [
          {
            id: 1,
            rsa_id: [],
            umbral: '',
          },
        ],
      },
      alimentosSelect: [
        {
          id: 1,
          rsa_id: [],
          umbral: 0,
        },
      ],
      superficiesSelect: [
        {
          id: 1,
          rsa_id: [],
          umbral: 0,
        },
      ],
      manipuldoresSelect: [
        {
          id: 1,
          rsa_id: [],
          umbral: 0,
        },
      ],
      aguasSelect: [
        {
          id: 1,
          rsa_id: [],
          umbral: 0,
        },
      ],
      ambientesSelect: [
        {
          id: 1,
          rsa_id: [],
          umbral: 0,
        },
      ],
      nuevoensayo: false,
      parametros_peigrosidad: [],
    },
    // matriz de peso bacterias
    matriz_peso: [
      {
        id: 1,
        Bacteria: 'Alicyclobacillus acidoterrestris',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 2,
        Bacteria: 'Aeromona hydrophila',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 3,
        Bacteria: 'Bacillus albus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 4,
        Bacteria: 'Bacillus licheniformis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 5,
        Bacteria: 'Bacillus megaterium',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 6,
        Bacteria: 'Bacillus thuringiensis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 7,
        Bacteria: 'Brochothrix thermosphacta',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 8,
        Bacteria: 'Citrobacter freundii',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 9,
        Bacteria: 'Enterobacter kobei/cloacae',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 10,
        Bacteria: 'Enterococcus faecium',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 11,
        Bacteria: 'Escherichia coli',
        Aguas: 10,
        Jugos1: 10,
        Jugos2: 10,
        Bebidas: 10,
      },
      {
        id: 12,
        Bacteria: 'Inhibicion',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 13,
        Bacteria: 'Klebsiella oxytoca',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 14,
        Bacteria: 'Lactobacillus helveticus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 15,
        Bacteria: 'Lactobacillus paracasei',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 16,
        Bacteria: 'Lactobacillus plantarum',
        Aguas: 10,
        Jugos1: 10,
        Jugos2: 10,
        Bebidas: 10,
      },
      {
        id: 17,
        Bacteria: 'Lactococcus lactis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 18,
        Bacteria: 'Leuconostoc citreum',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 19,
        Bacteria: 'Leuconostoc sp',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 20,
        Bacteria: 'Listeria monocytogenes',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 21,
        Bacteria: 'Negativo',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 22,
        Bacteria: 'Paenibacillus sp',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 23,
        Bacteria: 'Paenibacillus xylanilyticus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 24,
        Bacteria: 'Pseudomonas aeruginosa',
        Aguas: 10,
        Jugos1: 10,
        Jugos2: 10,
        Bebidas: 10,
      },
      {
        id: 25,
        Bacteria: 'Pseudomona alcaligens',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 26,
        Bacteria: 'Pseudomonas mendocina',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 27,
        Bacteria: 'Pseudomonas plecoglossicida',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 28,
        Bacteria: 'Pseudomonas putida',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 29,
        Bacteria: 'Serratia liquefaciens',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 30,
        Bacteria: 'Shewanella baltica',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 31,
        Bacteria: 'Staphylococcus aureus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 32,
        Bacteria: 'Staphylococcus epidermidis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 33,
        Bacteria: 'Staphylococcus hominis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 34,
        Bacteria: 'Streptococcus pyrogenes',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 35,
        Bacteria: 'Weissella cibaria',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 35,
        Bacteria: 'Weissella cibaria',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 36,
        Bacteria: 'Z. rouxii',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 37,
        Bacteria: 'Z. bailii',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 38,
        Bacteria: 'F. oxysporium',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
    ],
    // matriz de peso hongos y lev.
    matrizhongos_peso: [
      {
        id: 1,
        Bacteria: 'Alicyclobacillus acidoterrestris',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 2,
        Bacteria: 'Aeromona hydrophila',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 3,
        Bacteria: 'Bacillus albus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 4,
        Bacteria: 'Bacillus licheniformis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 5,
        Bacteria: 'Bacillus megaterium',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 6,
        Bacteria: 'Bacillus thuringiensis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 7,
        Bacteria: 'Brochothrix thermosphacta',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 8,
        Bacteria: 'Citrobacter freundii',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 9,
        Bacteria: 'Enterobacter kobei/cloacae',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 10,
        Bacteria: 'Enterococcus faecium',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 11,
        Bacteria: 'Escherichia coli',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 12,
        Bacteria: 'Inhibicion',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 13,
        Bacteria: 'Klebsiella oxytoca',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 14,
        Bacteria: 'Lactobacillus helveticus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 15,
        Bacteria: 'Lactobacillus paracasei',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 16,
        Bacteria: 'Lactobacillus plantarum',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 17,
        Bacteria: 'Lactococcus lactis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 18,
        Bacteria: 'Leuconostoc citreum',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 19,
        Bacteria: 'Leuconostoc sp',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 20,
        Bacteria: 'Listeria monocytogenes',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 21,
        Bacteria: 'Negativo',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 22,
        Bacteria: 'Paenibacillus sp',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 23,
        Bacteria: 'Paenibacillus xylanilyticus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 24,
        Bacteria: 'Pseudomonas aeruginosa',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 25,
        Bacteria: 'Pseudomona alcaligens',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 26,
        Bacteria: 'Pseudomonas mendocina',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 27,
        Bacteria: 'Pseudomonas plecoglossicida',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 28,
        Bacteria: 'Pseudomonas putida',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 29,
        Bacteria: 'Serratia liquefaciens',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 30,
        Bacteria: 'Shewanella baltica',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 31,
        Bacteria: 'Staphylococcus aureus',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 32,
        Bacteria: 'Staphylococcus epidermidis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 33,
        Bacteria: 'Staphylococcus hominis',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 34,
        Bacteria: 'Streptococcus pyrogenes',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 35,
        Bacteria: 'Weissella cibaria',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 35,
        Bacteria: 'Weissella cibaria',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
      {
        id: 36,
        Bacteria: 'Z. rouxii',
        Aguas: 10,
        Jugos1: 10,
        Jugos2: 10,
        Bebidas: 10,
      },
      {
        id: 37,
        Bacteria: 'Z. bailii',
        Aguas: 10,
        Jugos1: 10,
        Jugos2: 10,
        Bebidas: 10,
      },
      {
        id: 38,
        Bacteria: 'F. oxysporium',
        Aguas: 1,
        Jugos1: 1,
        Jugos2: 1,
        Bebidas: 1,
      },
    ],
    // info user
    userInfo: {},

  },
  mutations: {
    pushAlimentosArray(state, data) {
      // state.form_data.alimentosSelect.push({
      //   id,
      //   alimentos_id: [],
      //   umbral: 0,
      // });
      // console.log(state.form_data);
      state.form_data.alimentosSelect = data;
    },
    setRSA(state, data) {
      state.data_ensayo.rsa = data;
    },
    setMix(state, data) {
      state.data_ensayo.mixes = data;
    },
    // SET DATA ENSAYO
    setDataEnsayo(state, data) {
      state.form_data.data_ensayo = data;
      state.form_data.data_ensayo_original = JSON.parse(JSON.stringify(data));
    },
    setValidDataEnsayo(state, data) {
      state.form_data.data_ensayo_valid = data;
    },
    // SET DATA MATRIZ
    setDataMatriz(state, data) {
      function getMatrizFromId(idMatriz) {
        let matriz = null;
        state.obj_matrices.map((vv) => {
          if (vv.id === idMatriz) {
            matriz = vv;
          }
          return true;
        });
        return matriz;
      }
      //
      const matrices = [];
      data.map((v) => {
        const info = getMatrizFromId(v);
        matrices.push(info);
        return true;
      });
      //
      state.form_data.data_matrices = matrices;
    },
    setDataPatogens(state, data) {
      state.form_data.patogenos_selected = data;
    },
    setDataTecnicas(state, data) {
      state.form_data.data_tecnicas = data;
    },
    // PARA VER O NO VER LOS PASOS
    setFormStep(state, boo) {
      state.bool_form_steps = boo;
    },
    // SET PASO
    setStep(state, step) {
      state.form_data.step = step;
    },
    // PATOGENO QUE SELECCIONA
    setPatSelected(state, pat) {
      state.form_data.pat_select = pat;
    },
    // SET TECNICAS
    setTecnicas(state, data) {
      state.form_data.data_tecnicas = data;
    },
    setConstructor(state, array) {
      /* eslint-disable-next-line */
      state.selects[array[0]] = array[1];
    },
    setState(state, array) {
      /* eslint-disable-next-line */
      state[array[0]] = array[1];
    },

    // LIMPIA EL FORMULARIO
    clearAllDataForm(state) {
      state.form_data = JSON.stringify(state.layout_form);
      state.form_data = JSON.parse(state.form_data);
      state.model = {};
      state.form_data.matrices_unidades = [
        {
          id: 12,
          value: 'Alimento',
          unidades: '',
          activo: false,
        },
        {
          id: 9,
          value: 'Superficies',
          unidades: '',
          activo: false,
        },
        {
          id: 8,
          value: 'Manipuladores',
          unidades: '',
          activo: false,
        },
        {
          id: 7,
          value: 'Aguas',
          unidades: '',
          activo: false,
        },
        {
          id: 6,
          value: 'Ambientes',
          unidades: '',
          activo: false,
        },
      ];
      state.form_data.alimentosSelect = [
        {
          id: 0,
          alimentos_id: [],
          umbral: 0,
        },
      ];
      state.form_data.superficiesSelect = [
        {
          id: 0,
          zonas: [],
          umbral_critico: 0,
          umbral_nocritico: 0,
        },
      ];
      state.form_data.manipuldoresSelect = [
        {
          id: 0,
          manipulador: [],
          umbral: 0,
        },
      ];
      state.form_data.aguasSelect = [
        {
          id: 0,
          aguas: [],
          umbral: 0,
        },
      ];
      state.form_data.ambientesSelect = [
        {
          id: 0,
          ambiente: [],
          umbral: 0,
        },
      ];
    },
    datosParametrossel(state, data) {
      state.form_data.parametrosSelected = data;
    },
    datosEnsayo(state, data) {
      state.data_ensayo.rsa = data.rsa;
      state.data_ensayo.ensayosList = data.ensayosList;
      state.data_ensayo.productosList = data.productosList;
      state.data_ensayo.laboratoriosList = data.laboratoriosList;
      state.data_ensayo.parametros = data.parametros;
      state.data_ensayo.mix = data.mix;
      state.data_ensayo.tipo_resultados = data.tipo_resultados;
      state.data_ensayo.tipo_ensayos = data.tipo_ensayos;
      state.data_ensayo.tecnicas = data.tecnicas;
      state.data_ensayo.productosEmpresaslist = data.productosEmpresaslist;
      state.data_ensayo.empresasList = data.empresasList;
      state.data_ensayo.cliente = data.cliente;
      state.data_ensayo.nivelPeligro = data.nivelPeligro;
    },
    datosUser(state, datauser) {
      state.userInfo = datauser;
    },
    fillObjectForm(state, testToEdit) {
      // console.log('vamos a editar la info');
      if (testToEdit && testToEdit.data) {
        const dataTest = testToEdit.data[0];
        const codesPat = dataTest.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.cod_pat);
        state.form_data.data_ensayo.tipoEnsa = dataTest.tipo_ensayo_id;
        state.form_data.data_ensayo.tipoMeto = (dataTest.comercial) ? 1 : 0;
        state.form_data.data_ensayo.empresa = dataTest.ensayos_laboratorios[0].laboratorio_id;
        state.form_data.data_ensayo.ens_tipo = dataTest.d_tipo_de_ensayo;
        state.form_data.data_ensayo.descripcion = dataTest.ensa_informe;
        state.form_data.data_ensayo.metodologia = dataTest.metodologia;
        state.form_data.data_ensayo.numplacas = dataTest.numero_placas;
        state.form_data.data_ensayo.parametrosid = codesPat;
        state.form_data.data_ensayo.tiempoenriquecimiento = dataTest.tiempo_incubacion;
        state.form_data.data_ensayo.tiemporesult = dataTest.dias;
        state.form_data.data_ensayo.tecnica = dataTest.tecnicas_id;
        state.form_data.data_ensayo.productoid = dataTest.medio.idmedios;
        state.form_data.data_ensayo.tipoRes = dataTest.t_resultado;
        state.form_data.data_ensayo.sitioAnalisi = dataTest.ensayos_laboratorios[0].laboratorio_id;
        state.form_data.data_ensayo_original = dataTest.grupo_ensayo_id;

        testToEdit.data.forEach((test) => {
          const matrixObject = state.form_data.matrices_unidades;
          const matrixFound = matrixObject.findIndex((element) => element.id === test.matrices_id);
          state.form_data.matrices_unidades[matrixFound].activo = true;
          state.form_data.matrices_unidades[matrixFound].unidades = test.unidades;

          const groups = _.groupBy(test.umbrales, 'umbral');
          let umbral = [];

          Object.keys(groups).map((group) => {
            umbral.push({
              id: 0,
              umbral: groups[group][0].umbral,
              rsa_id: groups[group].map((umb) => umb.rsa_id),
            });
            return true;
          });

          const noUmbral = [{ id: 0, umbral: '', rsa_id: [] }];
          umbral = (umbral.length > 0) ? umbral : noUmbral;

          switch (test.matrices_id) {
            case 6: state.form_data.ambientesSelect = umbral; break;
            case 7: state.form_data.aguasSelect = umbral; break;
            case 8: state.form_data.manipuldoresSelect = umbral; break;
            case 9: state.form_data.superficiesSelect = umbral; break;
            case 12: state.form_data.alimentosSelect = umbral; break;
            default: break;
          }
          return true;
        });
      }
      // console.log('Despues de editar:', state.form_data);
    },
    addAlimento(state, newitem) {
      state.form_data.alimentosSelect = newitem;
    },
  },
};
