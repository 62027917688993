import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes = [
  {
    path: '/loginApi/:apiToken',
    name: 'loginApi',
    component: null,
    meta: { transition: 'zoom' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Login.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/ensayos',
    name: 'Ensayos',
    component: () => import('../components/Ensayos.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/analisis',
    name: 'Analisis',
    component: () => import('../components/Analisis.vue'),
    meta: { transition: 'zoom' },
  },
  {
    path: '/mantenedor',
    name: 'Mantenedor',
    component: () => import('../components/Mantenedor/Notas.vue'),
    meta: { transition: 'zoom' },
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;
