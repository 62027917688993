<style lang="scss">
@import "./assets/sass/global.scss";
body{
  --width: 50;
  --height: 48;
  --padding-top: 60;
  --padding-bottom: 60;
  --padding-wrapper: 15;
}
.o-toggle-modal__li  {
  svg{
    width: 29px;
    height: 29px;
    margin: 0.5rem auto;
    &.svg-menu{
        --svgcolor: #333;
        margin: auto;
      &__dashboard,
      &__home
      {
        --svgcolor: #666;
        width: 24px!important;
        height: 24px!important;
      }
      &__cerrarSesion{
        width: 18px;
        height: 18px;
      }
      &__otCompletas{
        // --svgcolor: #999;
        // width: 18px;
        // height: 18px;
      }
    }
  }
}
.o-toggle-modal{
  &__li{
    padding: 0.65rem 0rem!important;
    &:last-child{
      // background-color: #ededed;
    }
    &:hover{
        background-color: #333!important;
      svg {
          --svgcolor: #eee;
      }
    }
  }
}
.o-barra-superior {
  font-weight: lighter;
  font-weight: 300;
  background-color: #000;
  color: #fff;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 0 12px !important;
  font-size: 16px;
  display: grid;
  height: calc( var(--height) * 1px );
  // padding: 0 1.5rem !important;
  padding: 0 1.0rem !important;
  strong {
    font-weight: bold;
    font-weight: 600;
  }
}
.header-slide {
  padding: 4rem 0;
  justify-content: center;
}

#app {
  .v-navigation-drawer{
    background-color: #FFFFFF;
    visibility: visible;
    height: 100vh;
    top: 0px;
    transform: translateX(0%);
    width: calc(var(--width) * 1px) !important;
    max-height: calc(100% - 0px);
    transition: all 0.25s ease;
    &--open{
      transform: translateX(0px) !important;
    }
    &--close{
      transform: translateX(calc(var(--width) * -1px)) !important;
      & + #contenedor_btn_principal #wrapper{
        padding-left: calc( var(--padding-wrapper) * 1px );
      }
    }
    &--clipped{
      &:not(.v-navigation-drawer--temporary){
        &:not(.v-navigation-drawer--is-mobile) {
          z-index: 4;
        }
      }
    }
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    pointer-events: auto;
    top: 0;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    transition-property: transform, visibility, width;
    &--fixed {
      position: fixed;
    }
    &--mini-variant {
      overflow: visible !important;
    }
    &__content {
      padding-top: calc( var(--height) * 1px );
    }
    &__content {
      height: 100%;
      overflow-y: initial;
      overflow-x: initial;
    }
    &__content {
      background-color: #dcdee0;
      background-color: #dcdee05e;
    }
    & .o-toggle-modal__span{
      position: absolute;
      background-color: white;
      padding: 0.25rem 1.0rem;
      vertical-align: middle;
      display: inline-block;
      margin-left: 1rem;
      width: max-content;
      border-radius: 3px;
      box-shadow: 2px 1px 3px #a9a9a961;
      transition: all 0.25s ease-out;
      transform: translate(-75%, 0) scale3d(0, 0, 0);
      z-index: -1;
      opacity: 0;
    }
    a{
      text-align: center;
      padding: 0;
    }
    & .o-toggle-modal__li{
      // padding: 0;
      &:hover {
        & .o-toggle-modal{
          &__span{
            transform: translate(0%, 0) scale3d(1, 1, 1);
            z-index: 1;
            opacity: 1;
            left: 60%;
          }
        }
      }
      a{
        text-align: center;
        display: inline-flex;
        padding: 0;
      }
    }
  }
}
span.o-toggle-modal__span::before {
    content: "";
    background: white;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, -100px) rotate(45deg);
    box-shadow: -1px 0px 3px #a9a9a961;
    z-index: -1;
}
#sideNavigation,
.o-navbar-hamb
{
    display: none;
}
.c-analysis-to-perform__modal-body.scroll {
    min-height: 400px;
    max-height: calc(100vh - 230px);
}
.table-responsive{
    overflow-x: initial;
}
.o-logout{
  .v-list-item{
    padding: 0;
  }
  opacity: 0.7;
  :hover{
    opacity: 1;
  }
}
.v-application--is-ltr .v-list-item__avatar:first-child{
    margin: 0;
}
</style>
<template>
  <div id="app">
    <v-app id="inspire">
      <v-container class="o-barra-superior" fluid>
        <v-row class="">
          <v-col cols="12"><strong>TAAG</strong> Genetics</v-col>
        </v-row>
      </v-container>
      <v-app id="sandbox">
          <v-navigation-drawer
            v-model="primaryDrawer.model"
            :clipped="true"
            :mini-variant="true"
            :temporary="primaryDrawer.type === 'temporary'"
            permanent
            app
            overflow
          >
            <v-list dense>
              <v-list-item class="px-2 o-txa-logo">
                <v-list-item-avatar>
                  <!--<v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>-->
                  <v-img src="img/TxA-logo.svg"></v-img>
                </v-list-item-avatar>
                  <v-list-item-title>FSM</v-list-item-title>
                <v-btn
                  icon
                  @click.stop="mini = !mini"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
              <!--<v-divider></v-divider>-->
            <v-list dense>
              <v-list-item
                v-for="item in newNavItems"
                :key="item.title"
                :href="item.href"
                :icon="item.icon"
                target="_blank"
              >
                <v-list-item-content
                class="o-toggle-modal__li">
                    <svg class="svgMenu svg-menu " :class='`svg-menu__${ item.icon }`'>
                        <use :xlink:href='`/img/icons/icons.svg#${ item.icon }`'></use>
                    </svg>
                    <span class="o-toggle-modal__span">{{ item.title }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <!-- menu txa-lab -->
            <v-list dense>
              <v-list-item
                v-for="item in navitems"
                :key="item.title"
                :to="item.path"
                link
              >
                <div class="contents"
                  v-if="item.icon == 'ensayos' || item.icon == 'listaDetalles'"
                >
                  <v-list-item-content class="o-toggle-modal__li">
                      <svg class="svgMenu svg-menu " :class='`svg-menu__${ item.icon }`'>
                          <use :xlink:href='`/img/icons/icons.svg#${ item.icon }`'></use>
                      </svg>
                      <span class="o-toggle-modal__span">{{ item.title }}</span>
                  </v-list-item-content>
                </div>
                <div class="contents"
                  v-else
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </div>
              </v-list-item>
            </v-list>
            <v-list dense class="o-logout">
              <v-list-item
                key="exit"
                @click="salir()"
              >
                  <v-list-item-content class="o-toggle-modal__li">
                      <svg class="svgMenu svg-menu svg-menu__cerrarSesion">
                          <use xlink:href="/img/icons/icons.svg#cerrarSesion"></use>
                      </svg>
                      <span class="o-toggle-modal__span">Salir</span>
                  </v-list-item-content>
              </v-list-item>
            </v-list>

            <!-- fin menu txa-lab -->
            <v-list dense class="o-check-out">
              <!--
              <v-list-item
                key="exit"
                @click="salir()"
              >
                <v-list-item-icon>
                  <v-icon class="o-file-search__icon"
                        >mdi-export</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Salir</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              -->
              <v-list-item>
                <div class="o-user-menu">PR
                </div>
              </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </v-main>

      </v-app>
    </v-app>
    <v-overlay color="#010021" opacity="1" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="(!token)">
      <Login/>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import axios from 'axios';
import Service from './services/apis';

export default {
  name: 'App',

  components: {
    Login,
  },
  computed: {
    // loginReq: () => (!this.$ls.storage.token),
  },
  data: () => ({
    token: true,
    overlay: true,
    drawers: ['Default (no property)', 'Permanent', 'Temporary'],
    show: true,
    urlTXA: 'http://txa-lab-des.taag.mx/',
    primaryDrawer: {
      model: null,
      type: 'default (no property)',
      clipped: false,
      floating: false,
      mini: false,
    },
    footer: {
      inset: false,
    },
    newNavItems: [
      {
        title: 'Dashboard',
        icon: 'home',
        href: `${process.env.VUE_APP_TXA}home/dashboard`,
      },
      {
        title: 'Pre-OT',
        icon: 'preOt',
        href: `${process.env.VUE_APP_TXA}home/ListapreOTa`,
      },
      {
        title: 'OT & Muestras',
        icon: 'otMuestras',
        href: `${process.env.VUE_APP_TXA}home/ListaOTN`,
      },
      {
        title: 'OT Completas',
        icon: 'otCompletas',
        href: `${process.env.VUE_APP_TXA}home/ListaOTA`,
      },
    ],
    navitems: [
      { title: 'Ensayos', icon: 'ensayos', path: '/ensayos' },
      { title: 'Análisis', icon: 'listaDetalles', path: '/analisis' },
      // { title: 'Other', icon: 'mdi-account-group-outline', path: '/other' },
      // { title: 'Other', icon: 'mdi-account-group-outline', path: '/Other' }
    ],
  }),
  updated() {
    // console.log('montado', this.$route);
  },
  created() {
    this.interceptor();
    document.title = 'Slab LTS';
    this.$store.commit('setApp', this);
    const that = this;
    setTimeout(async () => {
      console.log('created app *************', that.$router.currentRoute.name);
      // CUANDO SE LOGEA POR API
      const routeName = that.$router.currentRoute.name;
      if (that.$router.currentRoute.name === 'loginApi' && that.$router.currentRoute.params.apiToken) {
        const validate = await that.verifyToken(that.$router.currentRoute.params.apiToken);
        if (validate) {
          localStorage.setItem('token', that.$router.currentRoute.params.apiToken);
        }
      }

      this.token = localStorage.getItem('token');
      this.token = (!this.token || this.token === 'null') ? null : this.token;
      if (this.token) {
        const validate = await that.verifyToken(this.token);
        if (!validate) {
          localStorage.setItem('token', null);
          this.token = null;
          return;
        }
        console.log('tiene token! procedo con init');
        // hacemos la peticion para los permisos
        const permisos = await Service.apiToken('POST', 'get-permiso', this.$ls.storage.token);
        // se arma el json de menu lateral
        const jsonmenu = JSON.parse(permisos.response.json);
        const menusteriplex = [];
        if (jsonmenu.slablts) {
          const permisosteriplex = Object.values(jsonmenu.slablts);
          permisosteriplex.forEach((element) => {
            if (element.activo === 1) {
              menusteriplex.push(element);
            }
          });
          this.navitems = menusteriplex;
        }

        // se termina de armar el menu
        this.init(routeName);
      } else if (window.location.pathname !== '/') {
        this.$router.push('/');
      }
      this.$ls.on('token', (val) => {
        that.token = val;
      });
    }, 500);
  },
  watch: {
    token(val) {
      this.$ls.storage.token = val;
    },
  },
  methods: {
    interceptor() {
      axios.interceptors.response.use((response) => response, (error) => {
        if (error.response.status === 401) {
        // place your reentry code
          this.localStorage('token', null);
          this.token = null;
        }
        return error;
      });
    },
    init(route = null) {
      this.token = this.$ls.storage.token;
      // SE OBTIENE LA BARRA LATERAL E INFORMACIÓN INICIAL
      if (route === null) {
        this.$router.push(this.navitems[0].path);
      } else if (route === 'loginApi') {
        this.$router.push('/analisis');
      } else {
        this.$router.push(`/${route}`);
      }

      setTimeout(() => {
        this.overlay = false;
      }, 500);
    },
    salir() {
      localStorage.removeItem('token');
      this.token = null;
      this.$router.push('/');
      // console.log('exit');
      // this.$ls.storage.token = null;
      // Vue.ls.remove('token');
    },
    setOverlay(value) {
      this.overlay = value;
    },
    async verifyToken(apiToken) {
      let response = false;
      await axios.post(`${process.env.VUE_APP_URL_API}login/verifyToken`, { token: apiToken }).then(() => {
        // console.log('verifytoken', result);
        response = true;
      }, (error) => {
        console.warn('error de login por api', error);
      });
      // this.$refs.loginComponent.loading = false;
      return response;
    },
  },
};
</script>
