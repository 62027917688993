import Vue from 'vue';
import Vuex from 'vuex';
// ENSAYOS
import Ensayos from './ensayos';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: null,
    apiToken: null,
    navOpen: false,
    count: 0,
    navigation_class: 'v-navigation-drawer--open',
  },
  mutations: {
    setToken(state, value) {
      state.apiToken = value;
    },
    setApp(state, app) {
      state.app = app;
    },
    /* Metodod para cambiar clases de un elemento html */
    toggleClass(state, obj) {
      const { el, clases } = obj;
      clases.map((clase) => el.classList.toggle(clase));
    },
    /* Metodod para (ocultar, mostrar) el navbar lateral */
    toogleNav() {
      const navigation = document.querySelector('.v-navigation-drawer');
      const toggleClass = ['v-navigation-drawer--close', 'v-navigation-drawer--open'];
      this.commit('toggleClass', { el: navigation, clases: toggleClass });
    },
  },
  actions: {

  },
  modules: {
    Ensayos,
  },
});
