<style lang="scss">
  .c-login{
      display: flex!important;
      align-items: center;
      justify-content: center;
      &__row{
        max-width: 100%;
      }
      &__elevation{
        --border-login: #bbbbbba8;
        .v-toolbar__content{
          justify-content: center;
          font-weight: 300;
        }
        .v-card__text,
        .v-card__actions{
          padding-left: 1.25rem!important;
          padding-right: 1.25rem!important;
          border-left: 2px solid var(--border-login);
          border-right: 2px solid var(--border-login);
          text-transform: uppercase;
        }
        .v-card__actions{
          padding-bottom: 1.5rem!important;
          border-bottom: 2px solid var(--border-login);
        }
        .v-btn{
          min-width: 6rem!important;
        }
      }
    .v-toolbar__title{
      text-transform: uppercase;
    }
  }
  .v-text-field{
    &__slot{
      input{
        &:-webkit-autofill{
          & ~ label{
            transform: translateY(-18px) scale(0.75);
          }
        }
      }
    }
    .v-label{
      padding: 0!important;
    }
  }
</style>
<style lang="css" scoped>
  /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
      transition: all .3s ease;
    }
    .slide-fade-leave-active {
      transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateX(10px);
      opacity: 0;
    }

    .msg-error{
      position: absolute;
      bottom: 3%;
    }
</style>
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card class="c-login">
          <v-row
            align="center"
            justify="center"
            class="c-login__row"
          >
            <v-col
              cols="12"
              sm="8"
              md="4"
            >
              <v-card class="c-login__elevation elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Login form</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <transition name="slide-fade">
                    <v-alert
                      border="left"
                      color="red lighten-2"
                      class="msg-error"
                      v-if="msgError"
                      dense
                      dark
                    >
                      {{ msgError }}
                    </v-alert>
                  </transition>
                  <v-form>
                    <div class="relative">
                    <v-text-field
                      v-model="formData.email"
                      label="Login"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                    ></v-text-field>
                    </div>
                    <div class="relative">
                      <v-text-field
                        v-model="formData.password"
                        id="password"
                        label="Password"
                        name="password"
                        prepend-icon="mdi-lock"
                        :type="type"
                      ></v-text-field>
                      <v-icon class="c-icon c-magnify" @click="changeType">{{eye}}</v-icon>
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="submit()" :loading="loading">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'Login',

  data: () => ({
    loading: false,
    valid: true,
    formData: {},
    dialog: true,
    notifications: false,
    sound: true,
    widgets: false,
    eye: 'mdi-eye',
    // changeType: function,
    type: 'password',
    msgError: null,
  }),
  methods: {
    activeLabels() {
      const labels = document.querySelectorAll('.v-input');
      labels.forEach((element) => {
        const inputField = element.querySelector('input');
        const labelField = element.querySelector('label');
        setTimeout(() => {
          inputField.parentNode.insertBefore(labelField, inputField.nextSibling);
        }, 0);
      });
    },
    async submit() {
      this.loading = true;
      // this.$store.state.app.setOverlay(this.loading);
      fetch(`${process.env.VUE_APP_URL_API}login`, {
        method: 'POST',
        body: JSON.stringify(this.formData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          // console.warn('Error:', er);
          this.msgError = err.error;
          setTimeout(() => {
            this.msgError = null;
          }, 1500);
        })
        .then((response) => {
          this.loading = false;
          if (!response.error) {
            this.$store.commit('setToken', response.token);
            localStorage.setItem('token', response.token);
            this.$store.state.app.init();
            // this.$store.
            return;
          }
          this.msgError = response.error;
          setTimeout(() => {
            this.msgError = null;
          }, 3500);

          // console.warn('ERROR', response);
        });
    },
    clear() {

    },
    changeType() {
      let text = 'password';
      if (this.type === 'password') {
        this.type = 'text';
        this.eye = 'mdi-eye-off';
        text = 'text';
      } else {
        this.type = 'password';
        this.eye = 'mdi-eye';
        text = 'password';
      }
      return text;
    },
  },
  mounted() {
    this.$nextTick(this.activeLabels);
  },
};
</script>
