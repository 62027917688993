// Install Axios from terminal
import axios from 'axios';
// import axios from "axios";
export default {
  apiToken: async (tipo, url, token, data) => {
    let R = null;

    await fetch(`${process.env.VUE_APP_URL_API}api/${url}`, {
      method: tipo,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      // eslint-disable-next-line
      .catch((err) => { console.warn('error', err); })
      .then((response) => {
        R = response;
      });

    return R;
  },
  api: async (tipo, url, data) => {
    let R = null;

    await axios.post(`${process.env.VUE_APP_URL_API}${url}`, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then((D) => {
      R = D;
    });

    // axios
    return R;
  },
  apidtc: async (tipo, token, data) => {
    let R = null;
    const url2 = 'https://dtcdes.taag.mx/sendStatusSample/';
    await fetch(`${url2}${data.muestraid}/${data.patogenoid}`)
      .then((res) => res.json())
      // eslint-disable-next-line
      .catch((err) => { console.warn('error',err)  })
      .then((response) => {
        R = response;
      });

    return R;
  },
};
